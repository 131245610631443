import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import Card from "../components/card"
import SEO from "../components/seo"

// markup
const IndexPage = () => {
  return (
    <div>
      <SEO />
      <div className="bg-gray-100 py-14 min-h-screen">
        <h1 className="mt-4 text-center text-3xl md:text-5xl text-green-700 font-bold">
          S.E.A. Sport Eventmanagement Agentur GmbH
        </h1>

        <div className="xl:flex xl:justify-center xl:space-x-8 xl:px-14">
          <Card
            title="E.D.G.A.R. Scooters"
            description="E.D.G.A.R. Scooters bietet mehrere verschiedene und hochwertige Scooter für diverse Bereiche an."
            link="https://edgarscooter.com"
          >
            <StaticImage className="w-72" src="../images/edgar-logo.png" alt="E.D.G.A.R. Scooter Logo" />
          </Card>

          <Card
            title="BGL Prestige"
            description="BGL Prestige ist ein sozialer Business Club in dem Unternehmen und Unternehmer sich in ungezwungener Atmosphäre austauschen."
            link="https://bgl-prestige.com/"
          >
            <StaticImage className="w-72" src="../images/bgl-prestige-logo.png" alt="BGL Prestig Logo" />
          </Card>

          <Card
            title="MR.SOURCING"
            description="MR.SOURCING ist der führende Anbieter von Geschenken, Prämien, Werbegeschenken und Geschenkartikeln mit Sitz in Österreich"
            link="https://www.mrsourcing.de/"
          >
            <StaticImage className="w-72" src="../images/mrsourcing-logo.png" alt="MR.Sourcing Logo" />
          </Card>

          <Card
            title="PELT"
            description="Pelt ist eine junge Modemarke die sich im Bereich Sport, Lifestyle & Accessoire angesiedelt hat."
            link="https://www.peltshop.com/"
          >
            <StaticImage className="w-72" src="../images/peltshop-logo.png" alt="Peltshop Logo" />
          </Card>
        </div>
      </div>

      <footer className="px-10 md:px-32 xl:px-64 py-16 bg-gray-700 text-sm">
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 text-white">
          <div className="lg:col-span-2">
            <div className="text-3xl mb-4">Impressum</div>
            <div className="text-white">
              <div className="mb-1">S.E.A. Sport Eventmanagement Agentur GmbH</div>
              <br />
              Waldstrasse 18
              <br />
              A-4616 Weisskirchen
              <br />
              Tel.: <a href="tel:0732 77 12 60">0732 77 12 60</a>
              <br />
              Fax: 0732 77 12 60 60
              <br />
              Email: <a href="mailto:office@s-e-a.at">office@s-e-a.at</a>
              <br />
              <div className="mt-1">UID-Nummer: ATU-54057700</div>
            </div>
          </div>
          <div>
            <div className="text-xl mb-4">Vertriebsassistent/in - Büro Manager/in</div>
            <p className="mb-3">
              Dieses anspruchsvolle Aufgabengebiet umfasst neben Erledigung und Nachverfolgung von Korrespondenz,
              Erstellung von Vorträgen und Präsentationen folgende ...
            </p>
            <a
              className="no-underline hover:underline text-green-400"
              href="/assets/docs/VertriebsassistentIn-Office-Management-MR.Sourcing-GmbH-per-April-2016.pdf"
              target="_blank"
            >
              Ganzes Inserat lesen
            </a>
          </div>
          <div>
            <div className="text-xl mb-4">Vegas Golf Vertriebspartner werden</div>
            <p className="mb-3">
              Werden Sie Partner von „Vegas Golf“ (www.vegasgolf.de) in Deutschland, Österreich, der Schweiz, Italien,
              Slowenien, Tschechien, der Slowakei, Belgien und Niederlande! <br />
              Vertreiben Sie das attraktivste und weltweit meistverkaufte „On Course Golfspiel“ zu attraktiven
              Bedingungen in einem stetig wachsenden Markt mit breit gestreuten Kundengruppen sowie ausgezeichneten
              Verdienstmöglichkeiten.
            </p>
            <a
              className="no-underline hover:underline text-green-400"
              href="/assets/docs/Vegas_Golf_vertreibspartner.pdf"
              target="_blank"
            >
              Ganzes Inserat lesen
            </a>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default IndexPage
