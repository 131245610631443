import * as React from "react"

interface Props {
  title: string
  description: string
  link: string
}

const Card: React.FC<Props> = ({ title, description, link, children }) => (
  <div className="mt-16 md:mt-24 py-4 px-4 bg-whit w-80 bg-white rounded-xl shadow-lg hover:shadow-xl transform hover:scale-110 transition duration-500 mx-auto xl:mx-0">
    <div className="w-sm">
      {children}
      <div className="mt-6 text-green-700 text-center">
        <h1 className="text-xl font-bold">{title}</h1>
        <p className="mt-4 text-gray-600">{description}</p>
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block mt-8 mb-4 py-2 px-14 rounded-full bg-green-700 text-white tracking-widest hover:bg-green-600 transition duration-200"
        >
          Zur Website
        </a>
      </div>
    </div>
  </div>
)

export default Card
